<template>
    <footer>
        <div class="foot_counten">
          <div class="productsServices">
            <h3>Products & Services</h3>
            <ul>
              <li><a href="https://www.sangfor.com/en/products/cyber-security" title="Cyber Security">Cyber Security</a></li>
              <li><a href="https://www.sangfor.com/en/products/infrastructure" title="IT Infrastructure">IT Infrastructure</a></li>
              <li><a href="https://www.sangfor.com/en/products/security-services" title="Security Services">Security Services</a></li>
            </ul>
          </div>
          <div class="Innovations">
            <h3>Innovations</h3>
            <ul>
              <li><a href="https://www.sangfor.com/en/innovations/security/neural-x" title="Neural-X">Neural-X</a></li>
              <li><a href="https://www.sangfor.com/en/innovations/security/engine-zero" title="Engine Zero">Engine Zero</a></li>
              <li><a href="https://www.sangfor.com/en/innovations/security/business-intelligence" title="Business Intelligence">Business Intelligence</a></li>
              <li><a href="https://www.sangfor.com/en/innovations/security/threat-intelligence" title="Threat Intelligence">Threat Intelligence</a></li>
              <li><a href="https://www.sangfor.com/en/innovations/security/z-sand" title="zSand">zSand</a></li>
              <li><a href="https://www.sangfor.com/en/innovations/security/botnet-detection" title="Botnet Detection">Botnet Detection</a></li>
              <li><a href="https://www.sangfor.com/en/innovations/security/ng-waf" title="NG WAF">NG WAF</a></li>
              <li><a href="https://www.sangfor.com/en/innovations/cloud-computing/srap" title="SRAP">SRAP</a></li>
            </ul>
          </div>
          <div class="Support">
            <h3>Support</h3>
            <ul>
              <li><a href="https://www.sangfor.com/en/support/services-overview/care-service" title="Sangfor Care Service">Sangfor Care Service</a></li>
              <li><a href="https://www.sangfor.com/en/support/services-overview/support-plans" title="Support Plans">Support Plans</a></li>
              <li><a href="https://www.sangfor.com/en/support/services-overview/case-handling" title="Case Handling Process">Case Handling Process</a></li>
              <li><a href="https://www.sangfor.com/en/support/services-overview/rma-process" title="RMA Process">RMA Process</a></li>
              <li><a href="https://www.sangfor.com/en/support/services-policy/warranty-policy" title="Warranty Policy">Warranty Policy</a></li>
              <li><a href="https://www.sangfor.com/en/support/services-policy/privacy-policy" title="Privacy Policy">Privacy Policy</a></li>
              <li><a href="https://community.sangfor.com/plugin.php?id=sangfor_databases:index" title="Support Community">Support Community</a></li>
              <li><a href="https://direct.lc.chat/9691255/1" title="LiveChat" target="_blank">LiveChat</a></li>
              <li><a href="https://community.sangfor.com/plugin.php?id=sangfor_databases:index" title="Tech Documents">Tech Documents</a></li>
              <li><a href="https://www.sangfor.com/en/support/services-policy/life-cycle-policy" title="Support Life Cycle Policy" target="_blank">Support Life Cycle Policy</a></li>
              <li><a href="https://www.sangfor.com/en/support/support-resources/support-case" title="Support Case">Support Case</a></li>
              <li><a href="https://www.sangfor.com/en/support/support-resources/faq" title="FAQ">FAQ</a></li>
            </ul>
          </div>
          <div class="Partners">
            <h3>Partners</h3>
            <ul>
              <li><a href="https://www.sangfor.com/en/partners/become-partner" title="Become a Partner">Become a Partner</a></li>
              <li><a href="https://www.sangfor.com/en/partners/partner-application-form" title="Partner Application Form" target="_blank">Partner Application Form</a></li>
              <li><a href="https://www.sangfor.com/en/partners/training-and-certification" title="Cyber Security">Training and Certification</a></li>
              <li><a href="https://community.sangfor.com/member.php?mod=logging&action=login&from=elearning" title="Partner e-Learning" target="_blank">Partner e-Learning</a></li>
            </ul>
          </div>
          <div class="Resources">
            <h3>Resources</h3>
            <ul>
              <li><a href="https://www.sangfor.com/en/info-center/events-webinars" title="Events & Webinars">Events & Webinars</a></li>
              <li><a href="https://www.sangfor.com/en/info-center/news-and-press-release" title="News & Press Release">News & Press Release</a></li>
              <li><a href="https://www.sangfor.com/en/info-center/blog-center" title="Blog">Blog</a></li>
              <li><a href="https://www.sangfor.com/en/info-center/success-stories" title="Success Stories">Success Stories</a></li>
              <li><a href="https://www.sangfor.com/en/info-center/download-center" title="Download Center">Download Center</a></li>
              <li><a href="https://www.sangfor.com/en/info-center/video-center" title="Videos">Videos</a></li>
            </ul>
          </div>
          <div class="About Us">
            <h3>About Us</h3>
            <ul>
              <li><a href="https://www.sangfor.com/en/about-us/company-profile" title="Company Profile">Company Profile</a></li>
              <li><a href="https://www.sangfor.com/en/about-us/awards-and-achievements" title="Awards & Achievements">Awards & Achievements</a></li>
              <li><a href="https://www.sangfor.com/en/about-us/careers" title="Careers">Careers</a></li>
              <li><a href="https://www.sangfor.com/en/about-us/investor-relations" title="Investor Relations">Investor Relations</a></li>
              <li><a href="https://www.sangfor.com/en/about-us/press-media-relations" title="Press & Media Relations">Press & Media Relations</a></li>
              <li><a href="https://www.sangfor.com/en/about-us/contact-us" title="Contact Us">Contact Us</a></li>
            </ul>
          </div>
        </div>
        <p class="copyright">COPYRIGHT © 2000-2021 SANGFOR TECHNOLOGIES. ALL RIGHTS RESERVED.</p>
      </footer>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
    footer{
      width: 100%;
      background: #232949;
      .foot_counten{
        width: 100%;
        display: flex;
        padding: 0 200px;
        margin: 0 auto;
        justify-content: space-between;
        div{
          width: 12%;
          padding: 57px 0 28px 0;
          h3{
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 20px;
            text-align: left;
          }
          ul{
            li{
              font-size: 14px;
              line-height: 26px;
              text-align: left;
              a{
                color: #77797E;
                  &:hover{
                  color: #FFFFFF;
                }
              }
            }
          }
        }
      }
      .copyright{
        padding: 10px 0;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
      }
    }
    ul,li{
    list-style: none;
    cursor: pointer;
    white-space: nowrap;
    margin: 0;
}
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, button, input, textarea, /* form elements 表单元素 */
th, td,div,header { /* table elements 表格元素 */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
</style>