<template>
  <div class="dashbord">
    <header class="haeder">
      <nav-componment></nav-componment>
    </header>
    <main class="main">
      <router-view />
    </main>
    <footer class="footer" v-if="isHome">
      <footer-component></footer-component>
    </footer>
  </div>
</template>

<script>
import navComponment from "../../components/navComponment.vue";
import footerComponent from "../../components/footerCompontent.vue";
import { getGsmInfo, getPpuserInfo } from "../../api/login";
export default {
  name: "dashbord",
  data() {
    return {
      isHome: true,
      showLiveChat: false,
    };
  },
  components: { navComponment, footerComponent, },
  methods: {
    //当路由不是home的时候底部隐藏
    doMemuActive(toRouter) {
      if (!toRouter.meta.showfoote) {
        this.isHome = false;
        localStorage.setItem("library_isHome", false);
      } else {
        this.isHome = true;
        this.$store.commit(
          "SET_MENUDATA",
          JSON.parse(localStorage.getItem("library_menuData"))
        );
        localStorage.setItem("library_isHome", true);
      }
    },
  },
  created() {
    this.isHome = JSON.parse(localStorage.getItem("library_isHome"));
    this.$store.commit(
      "SET_MENUDATA",
      JSON.parse(localStorage.getItem("library_menuData"))
    );
    if (localStorage.getItem("library_userInfo")) {
      this.$store.commit(
        "SET_USERINFO",
        JSON.parse(localStorage.getItem("library_userInfo"))
      );
    }
    if (
      localStorage.getItem("library_userType") &&
      localStorage.getItem("library_userType") == "pp"
    ) {
      getPpuserInfo().then((res) => {
        localStorage.setItem("library_userInfo", JSON.stringify(res.data.user));
        this.$store.commit("SET_USERINFO", res.data.user);
      });
    } else if (
      localStorage.getItem("library_userType") &&
      localStorage.getItem("library_userType") == "gsm"
    ) {
      getGsmInfo().then((res) => {
        localStorage.setItem("library_userInfo", JSON.stringify(res.data.user));
        this.$store.commit("SET_USERINFO", res.data.user);
      });
    }
  },
  watch: {
    //监听路由变化
    $route(toRouter, fromRouter) {
      this.doMemuActive(toRouter);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashbord {
  width: 100%;
  .haeder {
    width: 100%;
  }
  .main {
    width: 100%;
    background: #f2f6ff;
  }
  .liveChat {
    position: fixed;
    right: 40px;
    top: 300px;
    width: 350px;
    height: 500px;
    background: #fff;
    border-radius: 8px;
  }
}
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, button, input, textarea, /* form elements 表单元素 */
th, td,div,header { /* table elements 表格元素 */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
</style>