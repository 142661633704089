<template>
  <div class="nav_list">
    <div class="nav_list_left">
      <img src="./../assets/img/imglog.png" alt="log" />
    </div>
    <div class="nav_list_center">
      <ul class="navUl">
        <li
          v-for="(item, index) in menuList"
          :key="item.menuId"
          @mouseover="handlehover('over', index)"
          @mouseleave="handlehover('leave')"
          class="navli"
          @click="handledetail(item.menuId)"
        >
          <!-- <transition name="navDot"
            ><span
              class="navDot"
              v-show="showNavMenu == index ? true : false"
            ></span
          ></transition> -->
          <a>{{ item.label }}</a>
          <transition name="navMenu-fade">
            <ul
              v-show="showNavMenu == index ? true : false"
              class="navMenu"
              ref="navMenu"
            >
              <li
                v-for="(child, cIndex) in item.children"
                :key="child.menuId"
                class="menuList"
                @mouseenter="hoverchild('enter', cIndex)"
                @mouseleave="hoverchild('leave')"
                @click="handleSubmenu(child)"
              >
                <p class="menuList_name">
                  <transition name="childDot"
                    ><div class="childDotBox">
                      <span
                        class="childDot"
                        v-show="showchildMenu == cIndex"
                      ></span></div
                  ></transition>
                  {{ child.label }}
                </p>
                <p class="menuList_info">{{ child.note }}</p>
                <i
                  class="el-icon-arrow-right"
                  v-if="child.children && child.children.length > 0"
                ></i>
                <transition name="navChildMenu">
                  <ul
                    class="navChildMenu"
                    ref="navChildMenu"
                    v-show="showchildMenu == cIndex"
                  >
                    <li
                      v-for="children in child.children"
                      :key="children.menuId"
                      class="childMenuList"
                      @mouseenter="hoverchildren('enter', cIndex)"
                      @mouseleave="hoverchildren('leave')"
                    >
                      <p
                        @click="handleThreeMenu(children)"
                        :title="children.label"
                      >
                        <transition
                          ><div class="childrenDotBox">
                            <span
                              class="childrenDot"
                              v-show="showchildrenMenu == cIndex"
                            ></span></div
                        ></transition>
                        {{ children.label }}
                      </p>
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
    <div v-if="isLogin && isGSM" @click="tohelp" class="help" title="help">
      <img src="../assets/img/logo.png" />
    </div>
    <div class="nav_list_right" v-if="isLogin">
      <div class="headPortrait">
        {{
          this.$store.state.userInfo.name
            ? this.$store.state.userInfo.name.substring(0, 1)
            : ""
        }}
      </div>
      <div
        class="userDropdown"
        @mouseenter="hoverUser('enter')"
        @mouseleave="hoverUser('leave')"
      >
        <div v-if="this.$store.state.userInfo.code" class="user-content">
          <p :title="this.$store.state.userInfo.name">
            {{ this.$store.state.userInfo.name }}
          </p>
          <p :title="this.$store.state.userInfo.code">
            {{ this.$store.state.userInfo.code }}
          </p>
        </div>
        <div v-else class="user-content">
          <p :title="this.$store.state.userInfo.surname">
            {{ this.$store.state.userInfo.surname }}
          </p>
          <p :title="this.$store.state.userInfo.name">
            {{ this.$store.state.userInfo.name }}
          </p>
        </div>
        <i class="el-icon-arrow-down"></i>
        <transition name="showUser">
          <ul class="userDropdown_info" v-show="showUser">
            <li class="changePassword" @click="personalCenter">
              <div class="userInfoDot"><span></span></div>
              Personal Center
            </li>
            <li class="logOut" @click="logOut">
              <div class="userInfoDot"><span></span></div>
              Log Out
            </li>
          </ul>
        </transition>
      </div>
    </div>
    <div class="nav_list_right" v-else @click="handleLogin">Login</div>
    <div class="contact">
      <a
        href="https://www.sangfor.com/en/about-us/contact-us/contact-us-form"
        target="_blank"
        >Email Us</a
      >
    </div>
  </div>
</template>

<script>
import { outGsm, outPp, checkLoginApi, removeIdtToken } from "../api/login";
export default {
  name: "navComponment",
  data() {
    return {
      // isLogin: false,
      hover: false,
      showUser: false,
      isActive: 0,
      showNavMenu: -1,
      showchildMenu: -1,
      showchildrenMenu: -1,
      userInfo: {},
      menuList: [],
      isGSM: false,
    };
  },
  computed:{
    isLogin(){
      return !!localStorage.getItem("library_token");
    }
  },
  created() {
    this.menuList = JSON.parse(localStorage.getItem("library_menuData"));
    let userInfo = localStorage.getItem("library_userInfo")
      ? JSON.parse(localStorage.getItem("library_userInfo"))
      : "";
    this.$store.commit("SET_USERINFO", userInfo);
  },
  mounted(){
    this.$nextTick(()=>{
      if (this.isLogin && ['gsm','idt'].includes(localStorage.getItem('library_userType'))) {
        this.checkLogin();
      } 
    })
  },
  methods: {
    checkLogin() {
      checkLoginApi().then((res) => {
        const { rows } = res?.data;
        if (rows && rows.userId) {
          this.isGSM = true;
        }else{
          this.isGSM = false;
        }
      });
    },
    tohelp() {
      const token = localStorage.getItem("library_token");
      if (token) {
        if (process.env.NODE_ENV === "development") {
          window.open(
            `http://localhost:8088/gptAssistant?library_token=${localStorage.getItem(
              "library_token"
            )}`
          );
        } else {
          window.open("/gptAssistant");
        }
      } else {
        this.handleLogin();
      }
    },
    handlehover(data, index) {
      if (data === "over") {
        this.showNavMenu = index;
      } else {
        this.showNavMenu = -1;
      }
    },
    hoverchild(data, index) {
      if (data === "enter") {
        this.showchildMenu = index;
      } else {
        this.showchildMenu = -1;
      }
    },
    hoverchildren(data, index) {
      if (data === "enter") {
        this.showchildrenMenu = index;
      } else {
        this.showchildrenMenu = -1;
      }
    },
    hoverUser(data) {
      if (data === "enter") {
        this.showUser = true;
      } else {
        this.showUser = false;
      }
    },
    //点击跳转更改密码
    personalCenter() {
      this.$router.push({ path: "/personalCenter", query: { index: "1" } });
    },
    //点击跳转登录
    handleLogin() {
      this.$router.push({ path: "/login" });
    },
    //点击三级菜单
    handleThreeMenu(data) {
      let detailId = data.menuId;
      localStorage.setItem("library_tree", JSON.stringify(data));
      console.log(data);
      if (data.articleType == 0) {
        this.$router.push({
          name: "indexPage",
          params: { data: data, detailId: detailId },
          query: { module: data.menuId },
        });
      } else {
        this.$router.push({
          name: "mdArticle",
          params: { data: data, detailId: detailId },
          query: { module: data.menuId },
        });
      }
    },
    //点击一级菜单
    handledetail(id) {
      if (id == 1) {
        this.$router.push({ path: "/home" });
      }
    },
    //点击二级菜单
    handleSubmenu(data) {
      // if(!data.children||data.children.length<1){
      //   let detailId=data.menuId
      //   data=JSON.stringify(data)
      //   this.$router.push({ path: "/indexPage" ,query:{data:data,detailId:detailId}});
      // }
    },
    //登出
    logOut() {
      let params = {
        token: JSON.stringify(localStorage.getItem("library_token")),
      };
      if (
        localStorage.getItem("library_userType") &&
        localStorage.getItem("library_userType") == "pp"
      ) {
        outPp(params)
          .then((res) => {
            localStorage.removeItem("library_token");
            localStorage.removeItem("library_userInfo");
            localStorage.removeItem("library_userType");
            var url = "http://" + location.host + "/";
            location.href = url;
          })
          .catch(() => {
            localStorage.removeItem("library_token");
            localStorage.removeItem("library_userInfo");
            localStorage.removeItem("library_userType");
            var url = "http://" + location.host + "/";
            location.href = url;
          });
      } else if (
        localStorage.getItem("library_userType") &&
        localStorage.getItem("library_userType") == "gsm"
      ) {
        outGsm(params)
          .then((res) => {
            localStorage.removeItem("library_token");
            localStorage.removeItem("library_userInfo");
            localStorage.removeItem("library_userType");
            var url = "http://" + location.host + "/";
            location.href = url;
          })
          .catch(() => {
            localStorage.removeItem("library_token");
            localStorage.removeItem("library_userInfo");
            localStorage.removeItem("library_userType");
            var url = "http://" + location.host + "/";
            location.href = url;
          });
      }else if (
        localStorage.getItem("library_userType") &&
        localStorage.getItem("library_userType") == "idt"
      ){
        removeIdtToken(localStorage.getItem('token_by_code')).then(res=>{
          if (res.data.code == 0) {
            localStorage.removeItem("library_token");
            localStorage.removeItem("library_userInfo");
            localStorage.removeItem("library_userType");
            localStorage.removeItem("token_by_code");
            location.replace('/')
          }
        }).catch(err=>{
          this.$message.warning('请重试')
        })
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav_list {
  width: 100%;
  height: 90px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  .help {
    cursor: pointer;
  }
  .nav_list_left {
    width: 9%;
    height: 52px;
    cursor: pointer;
    img {
      width: auto;
      height: 100%;
    }
  }
  .nav_list_center {
    width: 70%;
    height: 100%;
    padding: 0 200px 0 120px;
    .navUl {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .navli {
        position: relative;
        padding: 0 14px;
        height: 100%;
        display: flex;
        align-items: center;
        &:hover {
          background: #eff1f7;
          a {
            color: #0d39a8;
          }
        }
        .navDot {
          position: absolute;
          top: 43px;
          left: 0;
          width: 6px;
          height: 6px;
          background: #65bb10;
          border-radius: 50%;
        }
        .navDot-enter-active {
          transition: all 0.3s ease;
        }
        .navDot-leave-active {
          transition: all 0.1s ease;
        }
        .navDot-enter {
          transform: translateX(-5px);
        }
        .navDot-leave-to {
          opacity: 0;
        }
        a {
          color: #9e9fb7;
          text-decoration: none;
          font-size: 20px;
          font-weight: 600;
        }
        .navMenu {
          position: absolute;
          top: 90px;
          left: -100px;
          background: #fff;
          padding: 15px 0;
          // box-shadow: 1px 1px 6px #DAE2F5;
          // box-shadow: 9px 10px 10px #c1c8db;
          border-radius: 8px;
          z-index: 2999;
          .menuList {
            position: relative;
            padding: 10px 60px 10px 30px;
            &:hover {
              background: #eff1f7;
              .menuList_name {
                color: #51555e;
                .childDotBox {
                  width: 16px;
                }
              }
            }
            .menuList_name {
              white-space: nowrap;
              font-size: 18px;
              color: #7f8394;
              text-align: left;
              display: flex;
              align-items: center;
              .childDotBox {
                width: 6px;
                transition: width 0.3s;
                display: flex;
                .childDot {
                  width: 6px;
                  height: 6px;
                  background: #65bb10;
                  border-radius: 50%;
                  margin-right: 10px;
                  transition: all 0.5s;
                }
              }
            }
            .menuList_info {
              white-space: nowrap;
              font-size: 14px;
              color: #ababae;
              text-align: left;
              margin-top: 8px;
            }
            .el-icon-arrow-right {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              right: 20px;
              font-size: 20px;
              color: #6b74f2;
            }
            .navChildMenu {
              width: 400px;
              background: #fff;
              border-radius: 8px;
              position: absolute;
              top: 0;
              right: -400px;
              z-index: 2999;
              // box-shadow: 9px 10px 38px #c1c8db;
              .childMenuList {
                padding: 5px 30px;
                border-radius: 8px;
                &:hover {
                  background: #eff1f7;
                  p {
                    color: #51555e;
                    .childrenDotBox {
                      width: 16px;
                    }
                  }
                }
                p {
                  width: 100%;
                  text-align: left;
                  font-size: 16px;
                  color: #7f8394;
                  padding: 15px 0;
                  display: flex;
                  align-items: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  .childrenDotBox {
                    width: 6px;
                    transition: width 0.3s;
                    display: flex;
                    .childrenDot {
                      width: 6px;
                      height: 6px;
                      background: #65bb10;
                      border-radius: 50%;
                      margin-right: 10px;
                      transition: all 0.3s;
                    }
                  }
                }
              }
            }
            .navChildMenu-enter-active,
            .navChildMenu-leave-active {
              transition: opacity 0.3s;
            }
            .navChildMenu-enter,
            .navChildMenu-leave-to {
              opacity: 0;
            }
          }
        }
        .navMenu-fade-enter-active,
        .navMenu-fade-leave-active {
          transition: opacity 0.3s;
        }
        .navMenu-fade-enter,
        .navMenu-fade-leave-to {
          opacity: 0;
        }
      }
    }
  }
  .nav_list_right {
    width: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    cursor: pointer;
    .headPortrait {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      color: red;
      font-size: 18px;
      text-align: center;
      line-height: 44px;
      background: #ccc;
      cursor: default;
    }
    .userDropdown {
      width: 150px;
      padding: 0 16px;
      font-size: 16px;
      color: #7a7b80;
      font-weight: 400;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      z-index: 999;
      .user-content {
        width: auto;
        p {
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      i {
        margin-left: 10px;
      }
      .userDropdown_info {
        width: 230px;
        position: absolute;
        top: 90px;
        right: 1px;
        background: #ffffff;
        box-shadow: 9px 10px 38px #c1c8db;
        border-radius: 8px;
        padding: 13px 30px;
        li {
          padding: 13px 10px;
          text-align: left;
          color: #7a7b80;
          display: flex;
          .userInfoDot {
            width: 6px;
            transition: width 0.3s;
            display: flex;
            align-items: center;
            span {
              width: 6px;
              height: 6px;
              background: #65bb10;
              border-radius: 50%;
              margin-right: 8px;
            }
          }
          &:hover {
            color: #51555e;
            .userInfoDot {
              width: 16px;
            }
          }
        }
      }
      .showUser-enter-active,
      .showUser-leave-active {
        transition: opacity 0.3s;
      }
      .showUser-enter,
      .showUser-leave-to {
        opacity: 0;
      }
      .userName {
        width: 90px;
        word-break: keep-all;
      }
    }
  }
  .contact {
    padding: 10px 20px;
    border-radius: 50px;
    background: #232949;
    a {
      color: #fff;
    }
    cursor: pointer;
  }
}
ul,
li {
  list-style: none;
  cursor: pointer;
  white-space: nowrap;
  margin: 0;
}
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, button, input, textarea, /* form elements 表单元素 */
th, td,div,header {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
